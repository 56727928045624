<template>
  <div>
    <slot name="header">
      <default-bar />
    </slot>

    <slot name="drawer">
      <default-drawer />
    </slot>

    <slot name="body">
      <default-view />
    </slot>

    <slot name="footer">
      <default-footer />
    </slot>

    <!-- <default-settings /> -->
    <v-fab-transition>
      <v-btn fab large dark color="pink" fixed right bottom @click="navigatePush('MenuAll')">
        <!-- <v-icon>mdi-arrow-right</v-icon> -->
        Menu
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
export default {
  name: 'MainLayout',

  components: {
    DefaultBar: () =>
      import(
        /* webpackChunkName: "main-app-bar" */
        './AppBar'
      ),
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "main-drawer" */
        './Drawer'
      ),
    DefaultFooter: () =>
      import(
        /* webpackChunkName: "main-footer" */
        './Footer'
      ),
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
  },
};
</script>
